import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Nav, Dropdown } from 'react-bootstrap';

import Icon from '../../../../components/Content/Icon.js';
import { getLoggedUser, getUserRolesForConsole } from '../../../User/UserActions.js';

class Menu extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false,
        };
    }

    getRoutes() {
        return [
            {
                to: '/console',
                name: 'dashboard',
                picto: 'tachometer-alt',
                allowed: ['admin', 'manager'],
            },
            {
                to: '/console/orders/onpreparation',
                name: 'orders',
                picto: 'list',
                allowed: ['admin', 'manager'],
            },
            // {
            //     to: '/console/order/prepare',
            //     name: 'prepare',
            //     picto: 'paper-plane',
            //     allowed: ['admin', 'manager'],
            // },
            {
                to: '/console/products',
                name: 'products',
                picto: 'cubes',
                allowed: ['admin'],
            },
            {
                to: '/console/products/stock',
                name: 'stock',
                picto: 'cubes',
                allowed: ['preparator'],
            },
            {
                to: '/console/users',
                name: 'users',
                picto: 'users',
                allowed: ['admin'],
            },
            {
                to: '/console/vouchers',
                name: 'vouchers',
                picto: 'tags',
                allowed: ['admin'],
            },
            {
                to: '/console/reviews',
                name: 'reviews',
                picto: 'comments',
                allowed: ['admin'],
            },
            {
                to: '/console/contents',
                name: 'cms',
                picto: 'book',
                allowed: ['admin'],
            },
            {
                to: '/console/shippings',
                name: 'shippings',
                picto: 'truck',
                allowed: ['admin'],
            },
            {
                to: '/console/config',
                name: 'configuration',
                picto: 'gears',
                allowed: ['admin'],
            },
        ];
    }

    toggleDropdown = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen,
        });
    };

    renderRoutes() {
        return this.getRoutes().map((route, index) => {
            if(route.name === 'divider') {
                return <Dropdown.Item key={route.name} divider />;
            } if(!route.allowed || (this.props.user && this.props.user.role && route.allowed.includes(this.props.user.role))) {
                return (
                    <Dropdown.Item key={route.name}>
                        <Nav.Link as={Link} to={`/${this.props.intl.locale}${route.to}`} className="text-dark p-1"><Icon icon={route.picto} /> <span className="d-inline">{route.name && <FormattedMessage id={route.name} />}</span></Nav.Link>
                    </Dropdown.Item>
                );
            }
            return null;
        });
    }

    render() {
        if(this.props.user && getUserRolesForConsole().includes(this.props.user?.role)) {
            return (
                <Dropdown show={this.state.dropdownOpen} onToggle={this.toggleDropdown} className="admin-menu">
                    <Dropdown.Toggle variant="link" className="p-0" suppressHydrationWarning>
                        <Icon icon="more-vertical" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu suppressHydrationWarning>
                        {this.renderRoutes()}
                    </Dropdown.Menu>
                </Dropdown>
            );
        }
        return null;
    }
}

function mapStateToProps(store) {
    return {
        user: getLoggedUser(store),
    };
}

Menu.defaultProps = {
    user: null,
};

Menu.propTypes = {
    intl: PropTypes.object.isRequired,
    user: PropTypes.object,
};

export default connect(mapStateToProps)(injectIntl(Menu));
